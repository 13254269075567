<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="error-content-box">
                    <h2>Oops,Something went wrong...</h2>
                    <p>Go to back page by clicking "Go To Backpage" button and try again or feel free to contact us if the problem persist.</p>
                    <a class="btn btn-backpage" (click)="goBack()">Go to Backpage</a>
                </div>
            </div>
        </div>
    </div>
</section>