import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.goBack();
    }, 5000);
  }
  goBack() {
    this.location.back();
  }
}
