// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  storage: sessionStorage,

  // baseUri:'https://app.pure.bi/api/',
  // APT_KEY:'690988255366-8pljg2hib281h6llvi660s1qpt25pm6b.apps.googleusercontent.com',
  // requestUri : 'https://app.pure.bi',
  //nodeUrl1:'https://oush42hfe6ydcmxiqr73mbzu5e0exasj.lambda-url.ap-south-1.on.aws/',
  // nodeUrl1:'http://localhost:4000/',

  // baseUri:'https://csc.pure.bi/api/',
  //requestUri : 'http://localhost:4200',
  // requestUri : 'https://csc.pure.bi',
  
  // nodeUrl:'https://njserv.pure.bi/api/',
  //nodeUrl:'http://localhost:4000/api/',
  //baseUri: 'https://new.pure.bi/api/',


 
  // Common

  nodeUrl:'https://njserv.pure.bi/api/',
  APT_KEY:'690988255366-8pljg2hib281h6llvi660s1qpt25pm6b.apps.googleusercontent.com',

  
  //Production

  baseUri:'https://app.pure.bi/api/',
  requestUri : 'https://app.pure.bi',
  nodeUrl1:'https://oush42hfe6ydcmxiqr73mbzu5e0exasj.lambda-url.ap-south-1.on.aws/',
  //nodeUrl1:'http://localhost:4000/',
  
  
  //Development
  
  // baseUri:'https://test.pure.bi/api/',
  // requestUri : 'https://test.pure.bi',
  // nodeUrl1:'https://5mdojpnyepplmfyh2r2sczhhne0sagcc.lambda-url.ap-south-1.on.aws/',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 //import 'zone.js/dist/zone-error';  // Included with Angular CLI.
